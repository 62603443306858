
const weatherSVGs = {};
weatherSVGs.precip = require('assets/images/localnews/weather/icon_precip.svg');
weatherSVGs.rain = require('assets/images/localnews/weather/icon_precip_rain.svg');
weatherSVGs.snow = require('assets/images/localnews/weather/icon_precip_snow.svg');
weatherSVGs.icon0 = require('assets/images/localnews/weather/icon_weather_00.svg');
weatherSVGs.icon1 = require('assets/images/localnews/weather/icon_weather_01.svg');
weatherSVGs.icon2 = require('assets/images/localnews/weather/icon_weather_02.svg');
weatherSVGs.icon3 = require('assets/images/localnews/weather/icon_weather_03.svg');
weatherSVGs.icon4 = require('assets/images/localnews/weather/icon_weather_04.svg');
weatherSVGs.icon5 = require('assets/images/localnews/weather/icon_weather_05.svg');
weatherSVGs.icon6 = require('assets/images/localnews/weather/icon_weather_06.svg');
weatherSVGs.icon7 = require('assets/images/localnews/weather/icon_weather_07.svg');
weatherSVGs.icon8 = require('assets/images/localnews/weather/icon_weather_08.svg');
weatherSVGs.icon9 = require('assets/images/localnews/weather/icon_weather_09.svg');
weatherSVGs.icon10 = require('assets/images/localnews/weather/icon_weather_10.svg');
weatherSVGs.icon11 = require('assets/images/localnews/weather/icon_weather_11.svg');
weatherSVGs.icon12 = require('assets/images/localnews/weather/icon_weather_12.svg');
weatherSVGs.icon13 = require('assets/images/localnews/weather/icon_weather_13.svg');
weatherSVGs.icon14 = require('assets/images/localnews/weather/icon_weather_14.svg');
weatherSVGs.icon15 = require('assets/images/localnews/weather/icon_weather_15.svg');
weatherSVGs.icon16 = require('assets/images/localnews/weather/icon_weather_16.svg');
weatherSVGs.icon17 = require('assets/images/localnews/weather/icon_weather_17.svg');
weatherSVGs.icon18 = require('assets/images/localnews/weather/icon_weather_18.svg');
weatherSVGs.icon19 = require('assets/images/localnews/weather/icon_weather_19.svg');
weatherSVGs.icon20 = require('assets/images/localnews/weather/icon_weather_20.svg');
weatherSVGs.icon21 = require('assets/images/localnews/weather/icon_weather_21.svg');
weatherSVGs.icon22 = require('assets/images/localnews/weather/icon_weather_22.svg');
weatherSVGs.icon23 = require('assets/images/localnews/weather/icon_weather_23.svg');
weatherSVGs.icon24 = require('assets/images/localnews/weather/icon_weather_24.svg');
weatherSVGs.icon25 = require('assets/images/localnews/weather/icon_weather_25.svg');
weatherSVGs.icon26 = require('assets/images/localnews/weather/icon_weather_26.svg');
weatherSVGs.icon27 = require('assets/images/localnews/weather/icon_weather_27.svg');
weatherSVGs.icon28 = require('assets/images/localnews/weather/icon_weather_28.svg');
weatherSVGs.icon29 = require('assets/images/localnews/weather/icon_weather_29.svg');
weatherSVGs.icon30 = require('assets/images/localnews/weather/icon_weather_30.svg');
weatherSVGs.icon31 = require('assets/images/localnews/weather/icon_weather_31.svg');
weatherSVGs.icon32 = require('assets/images/localnews/weather/icon_weather_32.svg');
weatherSVGs.icon33 = require('assets/images/localnews/weather/icon_weather_33.svg');
weatherSVGs.icon34 = require('assets/images/localnews/weather/icon_weather_34.svg');
weatherSVGs.icon35 = require('assets/images/localnews/weather/icon_weather_35.svg');
weatherSVGs.icon36 = require('assets/images/localnews/weather/icon_weather_36.svg');
weatherSVGs.icon37 = require('assets/images/localnews/weather/icon_weather_37.svg');
weatherSVGs.icon38 = require('assets/images/localnews/weather/icon_weather_38.svg');
weatherSVGs.icon39 = require('assets/images/localnews/weather/icon_weather_39.svg');
weatherSVGs.icon40 = require('assets/images/localnews/weather/icon_weather_40.svg');
weatherSVGs.icon41 = require('assets/images/localnews/weather/icon_weather_41.svg');
weatherSVGs.icon42 = require('assets/images/localnews/weather/icon_weather_42.svg');
weatherSVGs.icon43 = require('assets/images/localnews/weather/icon_weather_43.svg');
weatherSVGs.icon44 = require('assets/images/localnews/weather/icon_weather_44.svg');
weatherSVGs.icon45 = require('assets/images/localnews/weather/icon_weather_45.svg');
weatherSVGs.icon46 = require('assets/images/localnews/weather/icon_weather_46.svg');
weatherSVGs.icon47 = require('assets/images/localnews/weather/icon_weather_47.svg');

export default weatherSVGs;
